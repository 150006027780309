<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card
          icon="mdi-account-outline"
          color="secondary"
          style="background-color: #FAFAFA"
        >
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              Edit Profile
            </div>
          </template>

          <v-form
            ref="form"
            v-model="valid"
          >
            <v-container class="py-0">
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="firstName"
                    label="First Name"
                    required
                    :rules="[v => !!v || 'First name is required']"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="lastName"
                    label="Last Name"
                    required
                    :rules="[v => !!v || 'Last name is required']"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="phone"
                    label="Phone"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="email"
                    aria-autocomplete="username"
                    label="Email Address"
                    required
                    :rules="[v => !!v || 'Email address is required']"
                  />
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col>
                  <v-text-field
                    v-model="password"
                    label="Select a password"
                    class="purple-input"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[v => !!v || 'Password is required']"
                    required
                    aria-autocomplete="current-password"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="verifyPassword"
                    label="Re-enter password"
                    class="purple-input"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[v => !!v || 'Password is required']"
                    required
                    aria-autocomplete="current-password"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>
              </v-row> -->
              <template v-if="showAlert">
                <v-row>
                  <v-col>
                    <v-alert
                      prominent
                      type="error"
                    >
                      That email address is already in use. Please choose another one.
                    </v-alert>
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="primary"
                    class="mr-0"
                    :disabled="!valid"
                    @click="saveProfile()"
                  >
                    Update Profile
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ChatovateService from '@/services/ChatovateService.js'
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'

  export default {
    name: 'ACProfile',
    components: {
      ErrorMessage,
    },
    data: () => ({
      showErrorDialog: false,
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      password: '',
      verifyPassword: '',
      showPassword: false,
      error: '',
      loading: false,
      loadingMessage: 'Saving Profile',
      valid: true,
      userId: null,
      showAlert: false,
    }),
    computed: {
      ...mapGetters(['user']),
    },
    created () {
      this.firstName = this.user.first_name
      this.lastName = this.user.last_name
      this.email = this.user.email
      this.phone = this.user.phone
      this.userId = this.user.uuid
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      saveProfile: function () {
        this.loadingMessage = 'Saving Profile'
        this.loading = true
        this.valid = false
        const data = {
          id: this.userId,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
        }
        this.showAlert = false
        ChatovateService.updateProfile(data)
          .then(response => {
            const userData = this.user
            userData.first_name = this.firstName
            userData.last_name = this.lastName
            userData.email = this.email
            userData.phone = this.phone
            this.$store.commit('UPDATE_USER_DATA', userData)
            this.loading = false
            this.valid = true
          })
          .catch(error => {
            if (error.response.status === 418) {
              this.showAlert = true
              this.loading = false
              this.valid = true
            } else {
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
              this.loading = false
              this.valid = true
            }
          })
      },
    },
  }
</script>
